













import { Vue, Component, Prop } from 'vue-property-decorator';

import GridContainer from '@/shared/resources/components/grid/GridContainer.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';

@Component({
  components: {
    GridCol,
    GridRow,
    GridContainer,
  },
})
export default class PageHeader extends Vue {
  /**
   * Props
   */
  @Prop() private title!: string;
  @Prop() private subTitle!: string;
}
